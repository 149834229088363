import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import CoveragePage from './CoveragePage';
import './App.css'; // Para los estilos
import * as amplitude from '@amplitude/analytics-browser';

amplitude.init('479546b09e5c69116830d20f03ad65d1', undefined, {
  defaultTracking: false
});

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exac element={<HomePage />} />
        <Route path="/coverage" end element={<CoveragePage />} />
      </Routes>
    </Router>
  );
}

export default App;
