import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBt__Junbedug-ZPtNw88cwA-HeWfLrhrA",
  authDomain: "lula-salud-pilots.firebaseapp.com",
  projectId: "lula-salud-pilots",
  storageBucket: "lula-salud-pilots.appspot.com",
  messagingSenderId: "239510142422",
  appId: "1:239510142422:web:05df07a41ccf4caca3f877",
  measurementId: "G-DX37YVYZ1Y"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;
