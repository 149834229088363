import React, { useState, useRef, useEffect } from 'react';
import db from '../firebase';
import { addDoc, setDoc, collection, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import 'typeface-red-hat-text';
import './home-page.css'
import * as images from './../images/';
import Papa from 'papaparse';
import * as Sugar from 'sugar';
import * as amplitude from '@amplitude/analytics-browser';

function HomePage() {
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const [formData, setFormData] = useState({
        empresa: '',
        aseguradora: '',
        tipoSeguro: '',
        ciudad: '',
    });
    const [empresaError, setEmpresaError] = useState('');
    const [showOtherInsuranceInput, setShowOtherInsuranceInput] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        amplitude.track('home_page_viewed');
        return () => { };
    }, []);

    const aseguradoras = [
        'Allianz', 'GNP', 'Monterrey', 'Mapfre', "Atlas", 'Axa', 'MediAccess', 'Otro'
    ];
    const tiposSeguro = [
        'Seguro de Vida',
        'Seguro Gasto Médico Mayor',
        'Seguro Gasto Médico Menor',
        'Seguro Dental',
        'Seguro de Visión'
    ];
    const ciudades = ["Aguascalientes",
        "Baja California",
        "Baja California Sur",
        "Campeche",
        "Chiapas",
        "Chihuahua",
        "Ciudad De México",
        "Coahuila",
        "Colima",
        "Durango",
        "Estado De México",
        "Guanajuato",
        "Guerrero",
        "Hidalgo",
        "Jalisco",
        "Michoacán",
        "Morelos",
        "Nayarit",
        "Nuevo León",
        "Oaxaca",
        "Puebla",
        "Querétaro",
        "Quintana Roo",
        "San Luis Potosí",
        "Sinaloa",
        "Sonora",
        "Tabasco",
        "Tamaulipas",
        "Tlaxcala",
        "Veracruz",
        "Yucatán",
        "Zacatecas",
        "Extranjero",
        "México",
        "Michoacan",
        "Queretaro",
        "San Luis Potosi",
        "Estado De Mexico",
        "Baja California Norte",
        "Nuevo Leon",
        "Yucatan",
        "Ciudad De Mexico",
        "Col. Del Valle", "Mexico",
        "Distrito Federal"
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'aseguradora' && value === 'Otro') {
            setShowOtherInsuranceInput(true);
        } else if (name === 'aseguradora') {
            delete formData.otraAseguradora;
            setShowOtherInsuranceInput(false);
        }

        if (name === 'empresa') {
            if (value.length < 3) {
                setEmpresaError('El nombre de la empresa debe tener al menos 3 caracteres');
            } else {
                setEmpresaError('');
            }
        }
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async () => {
        const response = await addDoc(collection(db, "form_request"), {
            ...formData,
            date: new Date().toISOString()
        });
        try {
            if (formData.aseguradora === 'Otro') {
                setShowModal(true);
            } else {
                navigate(`/coverage?aseguradora=${formData.aseguradora}&ciudad=${formData.ciudad}&ref=${response.id}`);
            }
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };


    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => processResults(results.data),
            });
        }
    };

    const processResults = async (data) => {
        let mapping = {};
        let insurererName = '';
        if (data && data[0]) {
            if (data[0].NH) {
                insurererName = 'axa';
                mapping = {
                    address: 'Calle',
                    hospital: 'NombreComun',
                    state: 'Estado',
                    town: 'Municipio',
                    phone: 'Telefono1',
                };
            } else if (data[0].Direccion_Completa) {
                insurererName = 'monterrey';
                mapping = {
                    address: 'Direccion_Completa',
                    hospital: 'Nombre',
                    state: 'Estado',
                    town: 'Municipio',
                    phone: 'Telefono',
                };
            } else if (data[0]['Numero Exterior']) {
                insurererName = 'mediaccess';
                mapping = {
                    address: 'Direccion',
                    hospital: 'Nombre Comercial',
                    state: 'Estado',
                    town: 'Ciudad',
                };
            } else if (data[0]['Colonia']) {
                insurererName = 'atlas';
                mapping = {
                    address: 'Direcci�n_1',
                    hospital: 'Nombre_1',
                    state: 'Estado',
                    town: 'Municipio_1',
                    phone: 'Tel�fono',
                };
            } else if (data[0].Aseguradora) {
                insurererName = 'allianz';
                mapping = {
                    address: 'Dirección',
                    hospital: 'Nombre Comercial',
                    state: 'Estado',
                    town: 'Municipio',
                };
            } else if (data[0].ESTADO) {
                insurererName = 'mapfre';
                mapping = {
                    address: 'DIRECCION',
                    hospital: 'RAZON COMERCIAL',
                    state: 'ESTADO',
                    town: 'CIUDAD',
                    phone: 'TELEFONO'
                };
            } else {
                insurererName = 'gnp';
                mapping = {
                    address: 'direccionCompleta',
                    hospital: 'nombreComercial',
                    state: 'estado',
                    town: 'municipio',
                    phone: 'telefonoCompleto',
                };
            }

            const processedData = Sugar.Array.groupBy(data, ((item) => item[mapping.state].toLowerCase().trim()));
            Object.keys(processedData).forEach((key) => {
                if (key.toString().trim().length > 0) {
                    processedData[key] = processedData[key].map((item) => {
                        return {
                            address: capitalize(item[mapping.address]),
                            hospital: capitalize(item[mapping.hospital]),
                            state: capitalize(item[mapping.state]),
                            town: capitalize(item[mapping.town]),
                            phone: capitalize(item[mapping.phone]).toString().trim(),
                        }
                    });
                } else {
                    delete processedData[key];
                }
            });
            await setDoc(doc(db, "insurers", insurererName), processedData);
            console.log(insurererName);
        }
    };

    const capitalize = (text) => {
        return text
            ?.toLowerCase()
            ?.split(' ')
            ?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
            ?.join(' ') ?? '';
    };

    return (
        <>
            <div className={'container-home'}>
                <div className={'leftColumn'}>
                    <img src={images.Logo_Dark} className={'logo'} alt={'Logo'} height={20} />
                    <div className={'space'} />
                    <img src={images.BgImage} className={'image'} alt={'Imagen Motivacional'} />
                    <span className={'text'}>Conoce los hospitales con convenio para tu seguro</span>
                    <span className={'sub-text'}>Sabías que el 79% de los asegurados no saben utilizar, ni conocen los beneficios en salud?</span>
                </div>
                <div className={'rightColumn'}>
                    <img src={images.Logo_Light} className={'form-logo'} alt={'Logo'} />
                    <span className={'form-sub-text'}>Ingresa estos datos para entender cuál es tu plan y mostrarte sus beneficios y cobertura</span>
                    <div className={'formContainer'}>
                        <div className={empresaError ? 'error input-group' : 'input-group'}>
                            <input type="text" name="empresa" id="empresa" placeholder="Escribe el nombre de tu empresa" onChange={handleChange} />
                            <label htmlFor="empresa">Nombre de la empresa</label>
                            {empresaError && <span className="error-inline-text">{empresaError}</span>}
                        </div>
                        <div className="input-group">
                            <select name="aseguradora" id="aseguradora" onChange={handleChange}>
                                <option value="" hidden> </option>
                                {aseguradoras.map((aseguradora) => (
                                    <option key={aseguradora} value={aseguradora}>{aseguradora}</option>
                                ))}
                            </select>
                            <label htmlFor="aseguradora">Aseguradora</label>
                        </div>
                        {showOtherInsuranceInput && (
                            <div className="input-group">
                                <input type="text" name="otraAseguradora" id="otraAseguradora" placeholder="Escribe el nombre de la aseguradora" onChange={handleChange} />
                                <label htmlFor="otraAseguradora">Otra Aseguradora</label>
                            </div>
                        )}
                        <div className="input-group">
                            <select name="tipoSeguro" id="tipoSeguro" onChange={handleChange}>
                                <option value="" hidden> </option>
                                {tiposSeguro.map((tipo) => (
                                    <option key={tipo} value={tipo}>{tipo}</option>
                                ))}
                            </select>
                            <label htmlFor="tipoSeguro">Tipo de seguro</label>
                        </div>
                        <div className="input-group">
                            <select name="ciudad" id="ciudad" onChange={handleChange}>
                                <option value="" hidden> </option>
                                {ciudades.map((ciudad) => (
                                    <option key={ciudad} value={ciudad}>{ciudad}</option>
                                ))}
                            </select>
                            <label htmlFor="ciudad">Estado</label>
                        </div>
                        <button className={"submitButton"} onClick={handleSubmit} disabled={Object.values(formData).some(value => !value)}>
                            Conocer mi cobertura
                        </button>
                        {/* <input type="file" ref={fileInputRef} onChange={handleFileChange} /> */}
                    </div>
                </div>

            </div>
            {showModal && (
                <div className={'modal-container'}>
                    <div className={'modal-content'}>
                        <h2>¡Gracias!</h2>
                        <p>Trabajaremos para incluir esa aseguradora en nuestra base.</p>
                        <button onClick={() => {
                            setShowModal(false);
                        }}>Cerrar</button>
                    </div>
                </div>
            )}
        </>
    );
}

export default HomePage;
