import React, { useState, useEffect } from 'react';
import db from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import 'typeface-red-hat-text';
import './coverage-page.css'
import * as images from './../images/';
import * as amplitude from '@amplitude/analytics-browser';

function CoveragePage() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [benefits, setBenefits] = useState({});
    const [coverage, setCoverage] = useState([]);

    const location = useLocation();
    const itemsPerPage = 8;
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const totalPages = Math.ceil(coverage.length / itemsPerPage);
    const queryParams = new URLSearchParams(location.search);
    const aseguradora = queryParams.get('aseguradora')?.toLowerCase();
    const docId = queryParams.get('ref');
    const ciudad = queryParams.get('ciudad')?.toLowerCase();
    const displayedData = coverage.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const [emailError, setEmailError] = useState('');
    const [formData, setFormData] = useState({
        correo: '',
    });

    useEffect(() => {
        amplitude.track('coverage_page_viewed');
        return () => { };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const benefitsDoc = await getDoc(doc(db, "benefits", aseguradora));
            if (benefitsDoc.exists()) {
                setBenefits(benefitsDoc.data());
            } else {
                setBenefits(null);
            }
            const coverageDoc = await getDoc(doc(db, "insurers", aseguradora));
            if (coverageDoc.exists()) {
                const response = coverageDoc.data();
                const value = response[ciudad?.toLowerCase()] ?? [];
                setCoverage(value);
            } else {
                setCoverage([]);
            }
            setLoading(false);
        };
        fetchData();
    }, [aseguradora, ciudad]);



    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'correo') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                setEmailError('Correo electrónico inválido');
            } else {
                setEmailError('');
            }
        }

        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    async function handleSubmit() {
        if (docId) {
            amplitude.track('download_information', { email: formData.correo });
            const documentRef = doc(db, "form_request", docId);
            await updateDoc(documentRef, {
                correo: formData.correo
            });
            setFormData({ correo: '' });
            setShowModal(false);
            download(Papa.unparse(coverage));
        }
    }

    function download(csv) {
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.style.display = 'none';
        a.href = url;
        a.download = 'data.csv';

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
    }

    function getVisiblePages(current, total) {
        const maxVisiblePages = 10;
        const sidePagesCount = 5; // Páginas que se mostrarán a la izquierda y a la derecha de la actual

        let pages = [];

        if (current <= sidePagesCount) {
            pages = Array.from({ length: sidePagesCount }).map((_, idx) => idx + 1);
            pages.push("...");
            pages.push(...Array.from({ length: sidePagesCount }).map((_, idx) => total - sidePagesCount + idx + 1));
        } else if (current > total - sidePagesCount) {
            pages.push(...Array.from({ length: sidePagesCount }).map((_, idx) => idx + 1));
            pages.push("...");
            pages.push(...Array.from({ length: sidePagesCount }).map((_, idx) => total - sidePagesCount + idx + 1));
        } else {
            pages.push(...Array.from({ length: sidePagesCount }).map((_, idx) => idx + 1));
            pages.push("...");
            pages.push(current);
            pages.push("...");
            pages.push(...Array.from({ length: sidePagesCount }).map((_, idx) => total - sidePagesCount + idx + 1));
        }

        return pages;
    }

    return (
        <>
            {loading ? (
                <div className={'loading-container'}>
                    <img src={images.simpleLogo} alt={'Logo'} className={'loading-image'} />
                    <span className={'loading-text'}>{'Cargando información'}</span>
                </div>
            ) : coverage.length === 0 ? (
                <div className={'loading-container'}>
                    <img src={images.robot} alt={'Logo'} className={'error-image'} />
                    <span className={'error-text'}>{'Ops, lo sentimos'}</span>
                    <span className={'error-sub-text'}>{'Tuvimos un error y no logramos generar resultados en la consulta, te invitamos a volver a intentarlo más tarde'}</span>
                    <button className={'error-button'} onClick={() => navigate(-1)}>
                        {'Volver al inicio'}
                    </button>
                </div>) : (
                <>
                    <div className="container-coverage">
                        <img src={images.Logo_Light} className={'coverage-form-logo'} alt={'Logo'} />
                        <div className="header">
                            <span className={'title'} style={{ marginBottom: 8 }}>Tu cobertura</span>
                            <span className={'subtitle'} style={{ marginBottom: 28 }}>Aquí tienes toda la información relativa a los beneficios de los que puedes contar teniendo el plan en <span style={{ textTransform: 'capitalize' }}>{aseguradora}</span></span>
                            <hr />
                        </div>
                        <div className="content">
                            {benefits && benefits !== null && <div className="benefitsColumn">
                                <span className='column-title'>Beneficios</span>
                                <ul>
                                    {Object.entries(benefits).map(([title, details]) => (
                                        <li key={title}>
                                            <strong>{title}</strong>
                                            <ul>
                                                {details.map((detail, idx) => (
                                                    <li key={idx}>{detail}</li>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            </div>}
                            <div className="coverageColumn">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span className='column-title'>Cobertura</span>
                                        <span className={'subtitle'} style={{ marginBottom: 24 }}>Puntos de atención hospitalaria en tu ciudad</span>
                                    </div>
                                    <button className={"downloadButton"} onClick={() => { setShowModal(true); }} >
                                        Descargar información
                                    </button>
                                </div>
                                <div className={'card-container'}>
                                    {coverage.map((row) => {
                                        return (<div className={'card-coverage'}>
                                            <span>Estado: {row.state}</span>
                                            <span>Hospital: {row.hospital}</span>
                                            <span>Dirección: {row.address}</span>
                                            <span>Municipio: {row.town}</span>
                                            <span>Teléfono: {row.phone}</span>
                                        </div>);
                                    })}
                                </div>
                                <div className={'table-container'}>
                                    <table>
                                        <thead className="tableHeader">
                                            <tr>
                                                <th>Estado</th>
                                                <th>Hospital</th>
                                                <th>Dirección</th>
                                                <th>Municipio</th>
                                                <th>Teléfono</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayedData.map((row, idx) => (
                                                <tr key={row.hospital}>
                                                    <td>{row.state}</td>
                                                    <td>{row.hospital}</td>
                                                    <td>{row.address}</td>
                                                    <td>{row.town}</td>
                                                    <td>{row.phone}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {totalPages > 1 && (
                                    <div className="paginator">
                                        <button onClick={() => setCurrentPage(1)}>
                                            <img src={images.firstPage} alt={'Primera página'} />
                                        </button>
                                        <button disabled={currentPage === 1} onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}>
                                            <img src={images.prevPage} alt={'Página anterior'} />
                                        </button>

                                        {getVisiblePages(currentPage, totalPages).map((page, index) => {
                                            if (page === "...") {
                                                return <span key={index}>...</span>
                                            }
                                            return (
                                                <button
                                                    key={index}
                                                    className={currentPage === page ? "active" : ""}
                                                    onClick={() => setCurrentPage(page)}
                                                >
                                                    {page}
                                                </button>
                                            );
                                        })}

                                        <button disabled={currentPage === totalPages} onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}>
                                            <img src={images.nextPage} alt={'Página siguiente'} />
                                        </button>
                                        <button onClick={() => setCurrentPage(totalPages)} >
                                            <img src={images.lastPage} alt={'Última página'} />
                                        </button>
                                    </div>
                                )}

                            </div>
                        </div>
                        <footer className="footer">
                            <img src={images.Logo_Light} className={'logo'} alt={'Logo'} />
                        </footer>
                    </div>
                    {showModal && (
                        <div className={'modal-container'}>
                            <div className={'modal-content'}>
                                <h2>¡Gracias!</h2>
                                <span>Agradecemos tu colaboración para ir mejorando cada dia.</span>

                                <div className={emailError ? 'error input-group' : 'input-group'} style={{ marginTop: 32 }}>
                                    <input type="email" name="correo" id="correo" placeholder="Escribe tu correo corporativo" onChange={handleChange} />
                                    <label htmlFor="correo">Correo electrónico corporativo</label>
                                    {emailError && <span className="error-inline-text">{emailError}</span>}
                                </div>
                                <button className={"submitButton"} onClick={handleSubmit} disabled={emailError || Object.values(formData).some(value => !value)}>
                                    Descargar
                                </button>
                                <button className={"flatButton"} onClick={() => { setShowModal(false) }}>
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    )}
                </>

            )}

        </>
    );
}

export default CoveragePage;